
import { mapState, mapMutations } from "vuex";
import { getSupport } from "@/graphql/support";
import ContentStrapi from '../components/ContentStrapi.vue';
export default {
  components: {ContentStrapi},
  nuxtI18n: {
    paths: {
      "en-ca": "/support",
      "fr-ca": "/soutien",
    },
  },
  async asyncData({ app, i18n }) {
    const client = app.apolloProvider.defaultClient;
    const locale = i18n.locale.slice(0, 3) + i18n.locale.slice(3).toUpperCase();
    const supportResponse = await client.query({
      query: getSupport,
      variables: { locale: locale },
    });
    const support = supportResponse?.data?.soutien?.data?.attributes;
    
    return { support };
  },
  data() {
    return {
      support: {},
      show: false,
      anchorSelected: ""
    };
  },
  head() {
    return {
      title: this.support?.SEO?.MetaTitle,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.support?.SEO?.MetaDescription,
        },
        {
          hid: "og:description",
          name: "og:description",
          content: this.support?.SEO?.MetaDescription,
        },
        { hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
      ],
      script: [
        {
          src: "https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js",
          sandbox: "allow-same-origin allow-scripts allow-popups allow-forms",
          type: "text/javascript",
          charset: "UTF-8"
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => this.setPageAnimation("animBanner"), 500);

    if(!process.server){
      if(this.$i18n.locale == 'fr-ca'){
        var options = {
	"rootUrl": "https://app.five9.com/consoles/",
	"type": "chat",
	"title": "Clavardage Support PC UAP",
	"tenant": "Genuine Parts Company",
	"profiles": "UAP PC Support Chat - French",
	"showProfiles": false,
	"autostart": true,
	"theme": "default-theme.css",
	"surveyOptions": {
		"showComment": true,
		"requireComment": false
	},
	"fields": {
		"name": {
			"value": "",
			"show": true,
			"label": "Nom"
		},
		"email": {
			"value": "",
			"show": true,
			"label": "Courriel"
		},
		"Phone": {
			"value": "",
			"show": true,
			"label": "Téléphone",
			"required": true
		},
		"UserLocale": {
			"value": "fr_CA",
			"show": false
		}
	},
	"playSoundOnMessage": true,
	"allowCustomerToControlSoundPlay": false,
	"lang": "https://www.uapinc.com/chat_language_file_french.js",
	"showEmailButton": false,
	"hideDuringAfterHours": false,
	"useBusinessHours": false,
	"showPrintButton": false,
	"allowUsabilityMenu": false,
	"enableCallback": false,
	"callbackList": "",
	"allowRequestLiveAgent": false
};
Five9SocialWidget.addWidget(options);


      document.getElementsByClassName('five9-text')[0].innerText = "Clavardage";
      document.getElementsByClassName('five9-text')[1].innerText = "Clavardage";
      }
      else{
        var options = {
	"rootUrl": "https://app.five9.com/consoles/",
	"type": "chat",
	"title": "UAP PC Support Chat",
	"tenant": "Genuine Parts Company",
	"profiles": "UAP PC Support Chat - English",
	"showProfiles": false,
	"autostart": true,
	"theme": "default-theme.css",
	"surveyOptions": {
		"showComment": true,
		"requireComment": false
	},
	"fields": {
		"name": {
			"value": "",
			"show": true,
			"label": "Name"
		},
		"email": {
			"value": "",
			"show": true,
			"label": "Email"
		},
		"Phone": {
			"value": "",
			"show": true,
			"label": "Phone",
			"required": true
		},
		"UserLocale": {
			"value": "en_CA",
			"show": false
		}
	},
	"playSoundOnMessage": true,
	"allowCustomerToControlSoundPlay": false,
	"lang": "",
	"showEmailButton": false,
	"hideDuringAfterHours": false,
	"useBusinessHours": false,
	"showPrintButton": false,
	"allowUsabilityMenu": false,
	"enableCallback": false,
	"callbackList": "",
	"allowRequestLiveAgent": false
};
Five9SocialWidget.addWidget(options);


      document.getElementsByClassName('five9-text')[0].innerText = "Chat";
      document.getElementsByClassName('five9-text')[1].innerText = "Chat";

   
      }
    }
  },
  computed: {
    ...mapState(["pageScroll"]),
  },
  methods: {
    ...mapMutations({
      setPageAnimation: "SET_PAGE_ANIMATION",
    })
  },
};
