
export default {
  // content is query data from parent page
  props: {
    contentApi: {
      type: Object,
      required: true,
    },
  }
};
